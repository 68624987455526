import React from "react"
import SectionHeading from "./section-heading";
import {Link} from "gatsby";

class SharePopupModel extends React.Component {

    render() {

        return (
            <>
                <div>
                    <div className="modal fade" id={this.props.name} tabIndex="-1" role="dialog"
                         aria-labelledby={this.props.name+'Label'} aria-hidden="true">
                        <div className="modal-dialog" role="document">

                            <div className="modal-content">
                                <div className="modal-header">
                                    <SectionHeading  text={"Share On"} color={" theme-color-blue"}  />
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <ul className="list-group list-group-horizontal text-center position-relative" style={{width: 'fit-content' ,position: 'relative' ,left: '50%',transform: 'translateX(-50%)'}}>
                                        <li className="list-group-item">
                                            <Link className="sociallink" id="gatsby_whatsaap" to={'https://api.whatsapp.com/'} target={'_blank'}><i className="fa fa-whatsapp"> </i></Link>
                                        </li>
                                        <li className="list-group-item">
                                            <Link className="sociallink" id="gatsby_facebook" to={'https://www.facebook.com/'} target={'_blank'}><i className="fa fa-facebook-f"> </i></Link>
                                        </li>
                                        <li className="list-group-item">
                                            <Link className="sociallink" id="gatsby_twitter" to={'https://twitter.com/'} target={'_blank'}><i className="fa fa-twitter"> </i></Link>
                                        </li>
                                        <li className="list-group-item">
                                            <Link className="sociallink" id="gatsby_linkedin" to={'https://www.linkedin.com/'} target={'_blank'}><i className="fa fa-linkedin"> </i></Link>
                                        </li>
                                        <li className="list-group-item">
                                            <a className="sociallink" id="gatsby_email" href='mailto:foodsafety@cliniexperts.com'><i className="fa fa-envelope-o"> </i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SharePopupModel
