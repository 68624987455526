import React from "react"

class RegulatoryAssociatedFields extends React.Component {

    render() {
        let fields = [
            {key:"Implementation on", value:this.props.data.date_of_implementation || ''},
            {key:"Impacted Areas", value:this.props.data.impacted_areas_business || ''},
            {key:"Reference", value:this.props.data.reference || ''},
            {key:"Draft or Gazette", value:this.props.data.draft_or_gazette || ''}
        ];



        return (
            <>
                <div className="row">

                    {
                        fields.filter(e => e.value !== '').map((e,i) => (
                            <div className={i%2!==0?'col-12 col-md-6 text-left text-md-right':'col-12 col-md-6'}><p className={"mb-1 fsm-theme-very-small-text "+(this.props.keyColor || '')}><strong>{e.key}:</strong>&nbsp;&nbsp;<span className={this.props.valueColor || ''}>{e.value}</span></p></div>
                        ))
                    }
                </div>
            </>
        )
    }
}

export default RegulatoryAssociatedFields
