import React from "react"
import Tag from "./tag";
import {graphql, useStaticQuery} from "gatsby";

const TagsList = data =>  {
    let tags = data.data;
    if(data.data === null) tags =  [];

    let q = useStaticQuery(graphql`
  query {
      allWordpressTag {
        edges {
          node {
            link
            name
            slug
            wordpress_id
          }
        }
      }
  }`);


tags.map((e, i) =>{
    tags[i].link = q.allWordpressTag.edges.filter(e2 => e2.node.name === e.name)[0].node.link;
})


        return (
            <>
                <div className="container-fluid py-5">
                    <div className="row">
                        {tags.map((node,i) => (
                            <Tag data={node} key={i} />
                        ))}
                    </div>
                </div>

            </>
        )
}

export default TagsList
