import React from "react"

class SectionHeading extends React.Component {

    render() {
        return (
            <>
                <h2 className={"fsm-theme-category-heading "+this.props.color}>{this.props.text}</h2>
            </>
        )
    }
}

export default SectionHeading
